// This file must be syncronized with SymbolTypeEnum of back
const MAPLAYER_SYMBOL_TYPE = {
	DEFAULT: 1,
	DIRECTION: 2,
	PR: 3,
	FONTAWESOME_BRANDS_ICON: 4,
	FONTAWESOME_REGULAR_ICON: 5,
	FONTAWESOME_SOLID_ICON: 6,
	VERTICAL_SIGNAL: 7,
	HORIZONTAL_SIGNAL: 9,
	PERSONAL_ICON: 8
};

const MAPLAYER_SYMBOL_INDENTIFICATION = {
	SEPARATOR: "*",
	DIRECTION: "dir",
	PR: "pr",
	FONTAWESOME: "fa",
	VERTICAL_SIGNAL: "vs",
	HORIZONTAL_SIGNAL: "hs",
	PERSONAL_ICON: "pi"
};

function getFontAwesomeClass(symbolType){
	switch (symbolType){
		case MAPLAYER_SYMBOL_TYPE.FONTAWESOME_BRANDS_ICON:
			return "brands";
		case MAPLAYER_SYMBOL_TYPE.FONTAWESOME_REGULAR_ICON:
			return "regular";
		case MAPLAYER_SYMBOL_TYPE.FONTAWESOME_SOLID_ICON:
			return "solid";
		default:
			return null;
	}
}

export default {
	MAPLAYER_SYMBOL_TYPE,
	MAPLAYER_SYMBOL_INDENTIFICATION,
	getFontAwesomeClass
};
