export default Object.freeze({
	ROAD_NAME: "road",
	DIRECTION_NAME: "dir",
	MUNICIPALITY_NAME: "municipality",
	TYPE_NAME: "type",
	COLOR_NAME: "color",
	EXTENSION_NAME: "ext",
	PRD_NAME: "prd",
	ABD_NAME: "abd",
	PRF_NAME: "prf",
	ABF_NAME: "abf",
	CUMULD_NAME: "cumuld",
	CUMULF_NAME: "cumulf",
	GEOM_NAME: "geom",
	CUSTOMIZED_1: "customized___1",
	CUSTOMIZED_2: "customized___2",
	CUSTOMIZED_3: "customized___3",
	CUSTOMIZED_4: "customized___4",
	CUSTOMIZED_5: "customized___5",
	CUSTOMIZED_6: "customized___6",
	CUSTOMIZED_7: "customized___7",
	CUSTOMIZED_8: "customized___8",
	CUSTOMIZED_9: "customized___9",
	CUSTOMIZED_10: "customized___10",
	CUSTOMIZED_11: "customized___11",
	CUSTOMIZED_12: "customized___12",
	standardNames: function(){
		return [
			this.ROAD_NAME,
			this.DIRECTION_NAME,
			this.MUNICIPALITY_NAME,
			this.TYPE_NAME,
			this.COLOR_NAME,
			this.EXTENSION_NAME,
			this.PRD_NAME,
			this.ABD_NAME,
			this.PRF_NAME,
			this.ABF_NAME,
			this.CUMULD_NAME,
			this.CUMULF_NAME,
			this.GEOM_NAME,
			this.CUSTOMIZED_1,
			this.CUSTOMIZED_2,
			this.CUSTOMIZED_3,
			this.CUSTOMIZED_4,
			this.CUSTOMIZED_5,
			this.CUSTOMIZED_6,
			this.CUSTOMIZED_7,
			this.CUSTOMIZED_8,
			this.CUSTOMIZED_9,
			this.CUSTOMIZED_10,
			this.CUSTOMIZED_11,
			this.CUSTOMIZED_12
		];
	},
	standardColumns: function(){
		return [
			this.ROAD_NAME,
			this.DIRECTION_NAME,
			this.MUNICIPALITY_NAME,
			this.PRD_NAME,
			this.ABD_NAME,
			this.PRF_NAME,
			this.ABF_NAME,
			this.CUMULD_NAME,
			this.CUMULF_NAME,
			this.GEOM_NAME,
			this.TYPE_NAME,
			this.CUSTOMIZED_1,
			this.CUSTOMIZED_2,
			this.CUSTOMIZED_3,
			this.CUSTOMIZED_4,
			this.CUSTOMIZED_5,
			this.CUSTOMIZED_6,
			this.CUSTOMIZED_7,
			this.CUSTOMIZED_8,
			this.CUSTOMIZED_9,
			this.CUSTOMIZED_10,
			this.CUSTOMIZED_11,
			this.CUSTOMIZED_12
		];
	}
});
