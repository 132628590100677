<template>
	<div class="auscultation_map__menu">
		<v-expansion-panels
		v-model="isOpen2"
		class="tw-mb-[10px]"
		>
			<v-expansion-panel>
				<v-expansion-panel-header class="pt-0 pb-0">
					<h3>Liste des filtres</h3>
				</v-expansion-panel-header>

				<v-expansion-panel-content class="tw-max-h-[300px]">
					<div
					class="tw-flex tw-gap-[5px] tw-flex-col tw-mt-[10px]"
					v-if="
						filterCategories.length === 1 &&
							filterCategories[0] !== '' &&
							0 !== dynamicFilterList.length
					"
					>
						<div
						class="tw-flex"
						v-for="(filter, index) in dynamicFilterList"
						:key="filter.id"
						>
							<v-autocomplete
							class="background"
							multiple
							:items="allFilterData[filter.columnName]"
							:label="filter.name"
							item-value="value"
							item-text="label"
							v-model="filter.currentValue"
							@change="handleInput(filter);"
							clearable
							hide-details
							outlined
							dense
							/>
						</div>

						<ButtonSlot @click="resetValues()">
							Reset
						</ButtonSlot>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<!--<div
		class="filters mb-2"
		v-if="
			filterCategories.length === 1 &&
				filterCategories[0] !== '' &&
				0 !== dynamicFilterList.length
		"
		>
			<v-form
			ref="form"
			class="tw-flex tw-flex-row 2xl:tw-flex-nowrap tw-flex-wrap tw-gap-[5px] tw-w-[600px] 2xl:tw-w-[1000px]"
			>
				<div
				:class="index < 3 ? '2xl:tw-w-[300px] tw-basis-[calc(50%-5px)]' : 'tw-w-[150px] tw-basis-[calc(33%-5px)]'"
				v-for="(filter, index) in dynamicFilterList"
				:key="filter.id"
				>
					<v-autocomplete
					class="background"
					multiple
					:items="allFilterData[filter.columnName]"
					:label="filter.name"
					item-value="value"
					item-text="label"
					v-model="filter.currentValue"
					@change="handleInput(filter);"
					clearable
					hide-details
					/>
				</div>
			</v-form>
		</div>-->

		<v-expansion-panels
		v-model="isOpen"
		class="tw-max-w-[350px] tw-w-fit"
		>
			<v-expansion-panel>
				<v-expansion-panel-header class="pt-0 pb-0">
					<h3>Cartographie des données</h3>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-treeview
					class="miniCheckbox"
					:return-object="true"
					selectable
					v-model="selected"
					:items="items"
					selected-color="darkgrey"
					off-icon="mdi-checkbox-blank-outline"
					on-icon="mdi-checkbox-marked"
					item-disabled="locked"
					@input="getSelectedCategory"
					ref="treeview"
					>
						<template
						v-slot:append="{ item }"
						>
							<v-icon
							v-if="item.geomType === 3"
							style="margin-right: 5px;
							color: #505050"
							>
								{{ 'mdi-rotate-3d' }}
							</v-icon>

							<v-icon
							v-if="item.imageSettings.length !== 0"
							style="color: #505050"
							>
								{{ 'mdi-image-multiple' }}
							</v-icon>

							<v-icon
							v-if="item.isGroundSurvey"
							style="color: #505050"
							>
								{{ 'mdi-terrain' }}
							</v-icon>
						</template>
					</v-treeview>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import MAPLAYER_STATUS from "../../../utils/map/maplayerstatus.const";
import GENERATION_STATUS from "../../../utils/generationstatus.const";
import {mapGetters} from "vuex";

export default {
	name: "AuscultationMapMenu",
	props: ["layersList", "campaign"],
	data: () => ({
		items: [],
		allItemsUnfilter: [],
		selected: [],
		isOpen: 0,
		isOpen2: 0,
		selectedFirst: false,
		dynamicFilterList: [],
		treeviewSelectedParentsNames: null,
		filterCategories: [],
		allFilterData: []
	}),
	computed: {
		...mapGetters("groundSurvey", ["isDisplayedRT"]),
		lastItemSelected(){
			if(this.selected.length === 0) return "Aucune";
			let lastSelected = this.selected[this.selected.length - 1];
			return this.$cap(lastSelected.name);
		}
	},
	watch: {
		selected(select){
			let findParent = [];
			let findChildren = [];
			select.forEach(e => {
				if(e.isGroupement === true){
					findParent.push(this.allItemsUnfilter.filter(item => e.id === item.id));
				}
			});
			let i = 1;
			findParent.forEach(e => {
				if(e.length > 0){
					if(e[0].children !== null){
						e[0].children.forEach(child => {
							child.groupement = i;
							child.parentName = e[0].name;
							findChildren.push(child);
						});
						i++;
					}
				}
			});
			this.$emit(
				"layerSelected",
				this.selected.concat(findChildren).map(el => {
					return {
						"id": el.id,
						"groupement": el.groupement || null,
						"parentName": el.parentName || null
					};
				})
			);
			if(
				"" !== this.filterCategories[0] 
				&& 0 !== this.filterCategories.length
			){
				this.getFiltersList();
			}
		},
		isDisplayedRT(){
			this.items = this.getMenuItems(this.layersList);
		},
	},
	methods: {
		async resetValues(){
			let filterIds = this.selected.map(filter => filter.id.split("_")[0]);
			await this.$api.mapLayers.resetAllFiltersByLayers(filterIds);
			this.dynamicFilterList.forEach(filter => {
				if(filter.currentValue !== undefined){
					filter.currentValue = [];
				}
			});
		},
		async handleInput(filter){
			clearTimeout(this.timeoutId);

			// Définissez un nouveau délai pour déclencher l'action
			this.timeoutId = setTimeout(async() => {
				await this.applyFilter(filter);
				this.updateFilters(filter);
			}, 3000);
		},
		async applyFilter(filter){
			const userId = this.$store.state.user.current.userId;
			if(userId){
				await this.$api.mapLayers
				.putNewFilter(filter.id, {
					value: filter.currentValue,
					userId: userId
				})
				.then(() => this.$emit("reloadLayers", [filter.currentValue]));
			}
		},
		updateFilters(excludedFilter = null){
			let dynamicFilterList = this.dynamicFilterList.filter(
				dynamicFilter =>
					dynamicFilter.id !=
          (null != excludedFilter ? excludedFilter.id : null)
			);
			dynamicFilterList.forEach(dynamicFilter => {
				this.$set(this.allFilterData, dynamicFilter.columnName, [dynamicFilter.currentValue]);
				this.getColumnFilter(dynamicFilter.columnName);
			});
		},
		getFilterValues(){
			this.dynamicFilterList.forEach((filter, index) => {
				this.$api.mapLayers
				.getFilterValueByUser(filter.id)
				.then(
					data => {
						if(data.length > 0){
							this.$set(this.dynamicFilterList[index], "currentValue", data[0].valueExact);
						}
					}
				);
			});
		},
		getSelectedCategory(){
			this.filterCategories = [];
			this.selected.forEach(layer => {
				if(!this.inArray(layer.category, this.filterCategories)) this.filterCategories.push(layer.category);
			});
		},
		inArray(value, arr){
			var status = false;

			for(var i = 0; i < arr.length; i++){
				var name = arr[i];
				if(name == value){
					status = true;
					break;
				}
			}

			return status;
		},
		changeExpansionPanel(isOpen){
			if(isOpen) this.isOpen = 0;
			else this.isOpen = null;
		},
		getMenuItems(layerList){
			return layerList.map(el => {
				return this.getMenuItem(el);
			}).filter(e => e !== undefined);
		},
		getMenuItem(layer){
			let locked = layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED;
			if(this.campaign.isGroundSurvey === false){
				if(layer.isGroundSurvey && this.isDisplayedRT === false){
					return;
				}
			}
			let item = {
				id: layer.id + "_Id",
				name: this.$cap(layer.name),
				children: this.getMenuItems(layer.children),
				locked: locked,
				category: layer.category,
				geomType: layer.geomType,
				isContainer: layer.isContainer,
				imageSettings: layer.imageSettings,
				isGroundSurvey: layer.isGroundSurvey,
				isGroupement: layer.isGroupement
			};
			if(!locked && !this.selectedFirst){
				if(!item.isGroupement){
					this.selectedFirst = true;
					this.selected.push(item);
				}
				else {
					if(item.isContainer){
						this.selectedFirst = true;
						this.selectLayerAndAllChidren(layer);
					}
				}
			}
			return item;
		},
		selectLayerAndAllChidren(layer){
			let locked = layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED;
			let childItem = {
				id: layer.id + "_Id",
				name: this.$cap(layer.name),
				children: this.getMenuItems(layer.children),
				locked: locked,
				category: layer.category,
				geomType: layer.geomType,
				isContainer: layer.isContainer,
				imageSettings: layer.imageSettings,
				isGroundSurvey: layer.isGroundSurvey,
				isGroupement: layer.isGroupement
			};
			this.selected.push(childItem);
			layer.children.forEach(child => {
				this.selectLayerAndAllChidren(child);
			});
		},

		getFiltersList(){
			this.$api.mapLayers
			.getFiltersList(this.$route.params.id, this.filterCategories[0], GENERATION_STATUS.GENERATION_FINISHED)
			.then(filters => {
				this.dynamicFilterList = filters;
				this.dynamicFilterList.forEach(filter => {
					this.getColumnFilter(filter.columnName);
				});
				this.getFilterValues();
			});
		},

		async getColumnFilter(columnName){
			await this.$api.mapLayers
			.getColumnFilter(
				this.$route.params.id,
				this.filterCategories[0],
				columnName,
				this.selected.map(el => el.id.substring(0, el.id.length - 3))
			)
			.then(columnFilters => {
				let filterValues = [];
				for(let key in columnFilters){
					filterValues.push({"value": key, "label": columnFilters[key]});
				}
          		this.allFilterData[columnName] = filterValues;
          		this.$forceUpdate();
			});
		},
		filterItems(){
			this.items.filter(item => {
				if(item.isGroupement === true){
					item.children = [];
				}
			});
		}
	},
	mounted(){
		this.items = this.getMenuItems(this.layersList);
		this.allItemsUnfilter = this.getMenuItems(this.layersList);
		this.filterItems();
		this.getSelectedCategory();
	}
};
</script>

<style lang="scss">
.auscultation_map__menu {
	max-width: 250px;
  font-family: Roboto, sans-serif;
  .panel-header_overtext {
    padding: 16px 18px 10px 18px;
    font-size: 20px;
  }
  .v-expansion-panel-header {
    font-size: 15px;
  }
  .v-expansion-panel-content {
    &__wrap {
      max-height: 74vh;
      overflow-y: auto;
      padding-left: 10px !important;
	  padding-right: 10px !important;
    }
  }
  .v-treeview-node {
    &__root {
      min-height: 30px;
    }
    &__children {
      min-height: 30px;
    }
  }

  .v-treeview-node__append {
	min-width: auto !important;
  }

  position: absolute;
  top: 10px;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 25%;
  .reset_button_container {
    display: flex;
    justify-content: center;
  }

  .miniCheckbox .mdi-checkbox-blank-outline::before,
  .miniCheckbox .mdi-checkbox-marked::before {
    transform: scale(0.8) !important;
  }

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    background: #290703ee;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  .filters {
    z-index: 1;
    min-width: 50vh;
  }
  .background {
	background-color: white;
	padding: 5px;
	border-radius: 4px
  }

  .v-expansion-panel--active > .v-expansion-panel-header{
	min-height: 30px !important;
	padding-top: 11px !important;
	margin-bottom: 15px !important;
  }

  .v-expansion-panel-header {
	padding-left: 19px !important;
	padding-right: 14px !important;
  }

  h3 {
	font-size: 17px !important;
  }

  .v-expansion-panel-content__wrap {
	padding-bottom: 6px !important
  }
  
}
</style>
