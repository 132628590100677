import configUtils from "../config.utils";
import MAPLAYER_TYPE from "./maplayertype.const";
import MAPLAYER_ZOOM from "./maplayerzoom.const";
import MAPLAYER_STATUS from "./maplayerstatus.const";
import SYMBOL_TYPE from "./symboltype.const";

export class TapeMeasure{
	constructor(){
		// GeoJSON object to hold our measurement features
		this.geojson = {
			type: "FeatureCollection",
			features: [],
		};
		this.domElementId = "";
		this.map = {};
		this.turf = null;
		this.activated = null;
	}

	initDistanceMeasurer(map, domElementId, activated, turf){
		this.domElementId = domElementId;
		this.map = map;
		this.turf = turf;
		this.activated = activated;
	}

	addDistanceMeasurer(){
		const distanceContainer = document.getElementById(this.domElementId);

		// Used to draw a line between points
		const linestring = {
			type: "Feature",
			geometry: {
				type: "LineString",
				coordinates: [],
			},
		};

		this.addSourceAndLayers();

		this.map.on("click", (e) => {
			if(!this.activated()) return;
			const features = this.map.queryRenderedFeatures(e.point, {
				layers: ["measure-points"],
			});

			// Remove the linestring from the group
			// so we can redraw it based on the points collection.
			if(this.geojson.features.length > 1) this.geojson.features.pop();

			// Clear the distance container to populate it with a new value.
			distanceContainer.innerHTML = "";

			// If a feature was clicked, remove it from the map.
			if(features.length){
				const id = features[0].properties.id;
				this.geojson.features = this.geojson.features.filter(
					(point) => point.properties.id !== id
				);
			}
			else {
				const point = {
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [e.lngLat.lng, e.lngLat.lat],
					},
					properties: {
						id: String(new Date().getTime()),
					},
				};
				if(this.geojson.features.length > 1){
					this.geojson.features = [];
				}
				this.geojson.features.push(point);
			}

			if(this.geojson.features.length > 1){
				linestring.geometry.coordinates = this.geojson.features.map(
					(point) => point.geometry.coordinates
				);

				this.geojson.features.push(linestring);

				// Populate the distanceContainer with total distance
				const value = document.createElement("div");
				const distance = this.turf.length(linestring);
				value.textContent = `Distance: ${distance.toLocaleString()}km`;
				//value.style.background = "white";
				distanceContainer.appendChild(value);
			}

			this.map.getSource("geojson").setData(this.geojson);
		});

		this.map.on("mousemove", (e) => {
			if(!this.activated()) return;
			const features = this.map.queryRenderedFeatures(e.point, {
				layers: ["measure-points"],
			});
			// Change the cursor to a pointer when hovering over a point on the map.
			// Otherwise cursor is a crosshair.
			this.map.getCanvas().style.cursor = features.length
				? "pointer"
				: "crosshair";
		});
	}
	addSourceAndLayers(){
		this.map.addSource("geojson", {
			type: "geojson",
			data: this.geojson,
		});

		// Add styles to the map
		this.map.addLayer({
			id: "measure-points",
			type: "circle",
			source: "geojson",
			paint: {
				"circle-radius": 18,
				"circle-color": "#000",
			},
			filter: [
				"in", "$type", "Point"
			],
		});
		this.map.addLayer({
			id: "measure-lines",
			type: "line",
			source: "geojson",
			layout: {
				"line-cap": "round",
				"line-join": "round",
			},
			paint: {
				"line-color": "#000",
				"line-width": 2.5,
			},
			filter: [
				"in", "$type", "LineString"
			],
		});
	}
	clearDistanceMeasurer(){
		this.geojson = {
			type: "FeatureCollection",
			features: [],
		};
		this.map.getCanvas().style.cursor = "";
		this.map.getSource("geojson").setData(this.geojson);

		const distanceContainer = document.getElementById(this.domElementId);
		distanceContainer.innerHTML = "";
	}
}

export class LayerUtils{
	constructor(campaign, map, layerInfo, tileTpl){
		this.campaign = campaign;
		this.map = map;
		this.layerInfo = layerInfo;
		this.isVisible = false;
		this.tileTpl = tileTpl;    

		if(this.map && this.layerInfo){      
			if(!this.map.getSource(layerInfo.id + "_Source")){
				this.map.addSource(layerInfo.id + "_Source", {
					type: "vector",
					tiles: [tileTpl.replace("{campaign-id}", this.campaign.id).replace("{map-layer-id}", layerInfo.id)],
					"minzoom": this.layerInfo.minZoom ? this.layerInfo.minZoom : 0,
					"maxzoom": this.layerInfo.maxZoom ? this.layerInfo.maxZoom : 30
				});
			}
			if(!this.map.getLayer(layerInfo.id + "_Id")){
				this.addMapLayer(layerInfo);
			}
		}
	}

	getTileSourceAtZoom(layerInfo, currentZoom){
		let orgGeomType = layerInfo.orgGeomType;
		let geomType = layerInfo.geomType;
		let tileSource = "tile";    
		if(geomType == MAPLAYER_TYPE.POLYGON3D){
			if(currentZoom < MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL){
				if(MAPLAYER_TYPE.LINESTRING == orgGeomType || MAPLAYER_TYPE.MULTI_LINESTRING == orgGeomType){
					tileSource = "linestring";
				}
				else {
					tileSource = "polygon";
				}
			}
			else {
				tileSource = "3D";
			}
		}
		return tileSource;
	}

	getGeomTypeAtZoom(layerInfo, currentZoom){
		let orgGeomType = layerInfo.orgGeomType;
		let geomType = layerInfo.geomType;
		if(geomType == MAPLAYER_TYPE.POLYGON3D){
			if(currentZoom < MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL){
				if(MAPLAYER_TYPE.LINESTRING == orgGeomType || MAPLAYER_TYPE.MULTI_LINESTRING == orgGeomType){
					geomType = MAPLAYER_TYPE.LINESTRING;
				}
				else {
					geomType = MAPLAYER_TYPE.POLYGON;
				}
			}
			else {
				geomType = MAPLAYER_TYPE.POLYGON3D;
			}
		}
		return geomType;
	}

	addMapLabelLayer(layerInfo){
		let tileTpl = this.tileTpl;
		let labelSourceId = layerInfo.id + "_LabelSource";
		let labelLayerId = this.layerInfo.id + "_Label";
		let currentZoom = this.map.getZoom();
		let geomType = this.getGeomTypeAtZoom(layerInfo, currentZoom);
		let tileSource = this.getTileSourceAtZoom(layerInfo, currentZoom);
		let symbolType = layerInfo.symbolType;
		let offsetCoef = 0;
		if(symbolType === SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.PR){
			offsetCoef = 0.2;
		}

		let config = configUtils.getfilterConfig(
			MAPLAYER_TYPE.LABEL,  
			SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.DEFAULT,
			null,
			layerInfo.displayColumn,
			geomType,
			offsetCoef
		);
		config.layout["visibility"] = "none";
    
		this.map.addSource(labelSourceId, {
			type: "vector",
			tiles: [tileTpl.replace("{campaign-id}", this.campaign.id).replace("{map-layer-id}", layerInfo.id)],
			"minzoom": layerInfo.labelMinZoom ? layerInfo.labelMinZoom : 0,
			"maxzoom": layerInfo.labelMaxZoom ? layerInfo.labelMaxZoom : 30
		});

		this.map.addLayer({
			id: labelLayerId,
			source: labelSourceId,
			"source-layer": tileSource,
			...config
		});
	}

	addMapLayer(layerInfo){

		if(layerInfo.status !== MAPLAYER_STATUS.GENERATION_FINISHED){
			return;
		}

		let layerId = layerInfo.id + "_Id";
		let currentZoom = this.map.getZoom();
		let geomType = this.getGeomTypeAtZoom(layerInfo, currentZoom);    
		let tileSource = this.getTileSourceAtZoom(layerInfo, currentZoom);

		let config = configUtils.getfilterConfig(
			geomType, 
			layerInfo.symbolType, 
			layerInfo.symbolInfo,
			layerInfo.displayColumn,
			geomType,
			0
		);
		config.layout["visibility"] = "none";

		this.map.addLayer({
			id: layerId,
			source: layerInfo.id + "_Source",
			"source-layer": tileSource,
			...config
		});

		// Check if layer has 
		if(layerInfo.displayColumn){
			this.addMapLabelLayer(layerInfo);
		}
	}

	showLayer(){
		if(this.layerInfo){
			this.map.setLayoutProperty(
				this.layerInfo.id + "_Id",
				"visibility",
				"visible"
			);
			if(this.layerInfo.displayColumn){
				this.map.setLayoutProperty(
					this.layerInfo.id + "_Label",
					"visibility",
					"visible"
				);
			}
			this.isVisible = true;
		}
	}

	removeLayer(){
		if(this.layerInfo){
			let existedLayer = this.map.getLayer(this.layerInfo.id + "_Id");
			if(existedLayer){
				this.map.removeLayer(
					this.layerInfo.id + "_Id"
				);
			}
		}
	}

	removeSource(){
		if(this.layerInfo){
			let existedSource = this.map.getSource(this.layerInfo.id + "_Source");
			if(existedSource){
				this.map.removeSource(this.layerInfo.id + "_Source");
			}
		}
	}

	clear(){
		this.removeLayer();
		this.removeSource();
	}

	moveToDeepest(){
		if(this.layerInfo){
			let layers = this.map.getStyle().layers;
			layers.forEach(layer => {
				if(layer.id != this.layerInfo.id + "_Id" && layer.id != this.layerInfo.id + "_Label"){
					this.map.moveLayer(this.layerInfo.id + "_Id", layer.id);
					if(this.layerInfo.displayColumn){
						this.map.moveLayer(this.layerInfo.id + "_Label", layer.id);
					}
				}
			});
		}
	}

	hideLayer(){
		if(this.layerInfo){
			this.map.setLayoutProperty(
				this.layerInfo.id + "_Id",
				"visibility",
				"none"
			);
			if(this.layerInfo.displayColumn){
				this.map.setLayoutProperty(
					this.layerInfo.id + "_Label",
					"visibility",
					"none"
				);
			}
			this.isVisible = false;
		}
	}
}

export default {tapeMeasure: new TapeMeasure()};
