<template>
	<nav map-nav-button>
		<div>
			<ButtonSlot
			ref="prsBtnA"
			v-if="prsLayer.A"
			:_icon="require('@/assets/images/road/PRA.png')"
			_icon-size="25px"
			_theme="light-gray"
			_tooltip="Autoroute"
			_tooltip-dir="top"
			@click="addPrsLayer(prsLayer.A)"
			@reclick="removePrsLayer(prsLayer.A)"
			/>

			<ButtonSlot
			ref="prsBtnN"
			v-if="prsLayer.N"
			:_icon="require('@/assets/images/road/PRN.png')"
			_icon-size="25px"
			_theme="light-gray"
			_tooltip="National"
			_tooltip-dir="top"
			@click="addPrsLayer(prsLayer.N)"
			@reclick="removePrsLayer(prsLayer.N)"
			/>

			<ButtonSlot
			ref="prsBtnD"
			v-if="prsLayer.D"
			:_icon="require('@/assets/images/road/PRD.png')"
			_icon-size="25px"
			_theme="light-gray"
			_tooltip="Départemental"
			_tooltip-dir="top"
			@click="addPrsLayer(prsLayer.D)"
			@reclick="removePrsLayer(prsLayer.D)"
			/>
		</div>

		<div>
			<ButtonSlot
			@click="map.resetNorth(); map.resetNorthPitch()"
			_icon="mdi-compass"
			_icon-size="25px"
			_theme="light-gray"
			/>

			<ButtonSlot
			ref="measureBtnMap"
			@click="MeasuringOn()"
			@reclick="MeasuringOff()"
			_icon="mdi-tape-measure"
			_icon-size="25px"
			_theme="light-gray"
			/>

			<ButtonSlot
			@click="centerMap()"
			_icon="mdi-image-filter-center-focus"
			_icon-size="25px"
			_theme="light-gray"
			/>

			<ButtonSlot
			ref="styleBtnMap"
			@click="campaign === false ? changeMapStyle() : setStyle()"
			@reclick="campaign === false ? changeMapStyle() : setStyle()"
			_icon="mdi-layers"
			_icon-size="25px"
			_theme="light-gray"
			/>

			<ButtonSlot
			@click="centerGeoLoc()"
			_icon="mdi-crosshairs-gps"
			_icon-size="25px"
			_theme="light-gray"
			/>

			<ButtonSlot
			@click="map.zoomOut()"
			_icon="mdi-minus-circle-outline"
			_icon-size="25px"
			_theme="light-gray"
			/>

			<ButtonSlot
			@click="map.zoomIn()"
			_icon="mdi-plus-circle-outline"
			_icon-size="25px"
			_theme="light-gray"
			/>

			<ButtonSlot
			@click="enablePolygonDraw" 
			v-if="$hasRight('users.isSuperAdmin')"
			_icon="mdi-draw-pen"
			_icon-size="25px"
			_theme="light-gray"
			/>
		</div>

		<span
		v-show="measureValue !== false"
		class="measure"
		>
			Total distance: {{ measureValue }} km
		</span>
	</nav>
</template>

<script>
import * as turf from "@turf/turf";
import ImageUtils from "../../utils/image";
import {mapGetters} from "vuex";
import store from "@/store";

export default {
	name: "MapNavButton",
	components: {

	},
	props: {
		map: {},
		draw: {}
	},
	data(){
		return {
			prsLayer: {
				A: false,
				N: false,
				D: false
			},
			measureValue: false
		};
	},
	computed: {
		...mapGetters("coring", ["campaign"])
	},
	watch: {
	},
	methods: {
		async getPrsLayer(){
			await new Promise((resolve) => {
				setInterval(() => {
					if(this.map !== false)resolve();
				}, 10);
			});

			let arr = await Promise.all([
				this.$api.mapLayers.getCommonPRsLayer("AU PR"),
				this.$api.mapLayers.getCommonPRsLayer("RN PR"),
				this.$api.mapLayers.getCommonPRsLayer("RD PR"),
			]);

			this.prsLayer = {
				A: arr[0],
				N: arr[1],
				D: arr[2],
			};

			ImageUtils.replaceColor(
				require("@/assets/images/symbols/pr/64x64.png"), 
				"#ff0000",
				"#000000",
				"", 
				(image) => this.map.loadImage(
					image,
					(error, image) => {
						if(error) throw error;
						this.map.addImage("pr-" + this.prsLayer.A.id, image);
					}
				)
			);

			ImageUtils.replaceColor(
				require("@/assets/images/symbols/pr/64x64.png"), 
				"#008000",
				"#000000",
				"", 
				(image) => this.map.loadImage(
					image,
					(error, image) => {
						if(error) throw error;
						this.map.addImage("pr-" + this.prsLayer.N.id, image);
					}
				)
			);

			ImageUtils.replaceColor(
				require("@/assets/images/symbols/pr/64x64.png"), 
				"#FFFF00",
				"#000000",
				"", 
				(image) => this.map.loadImage(
					image,
					(error, image) => {
						if(error) throw error;
						this.map.addImage("pr-" + this.prsLayer.D.id, image);
					}
				)
			);

			for(const key in this.prsLayer){
				if(this.map.getSource("prs-" + this.prsLayer[key].id)) continue;

				this.map.addSource("prs-" + this.prsLayer[key].id, {
					type: "vector",
					tiles: [process.env.VUE_APP_BASE_URL + "map-layers/" + this.prsLayer[key].id + "/tiles/{z}_{x}_{y}.pbf"]
				});
			}
		},

		addPrsLayer(prs){
			this.map.addLayer({
				id: "prs-" + prs.id,
				source: "prs-" + prs.id,
				type: "symbol",
				"source-layer": "tile",
				layout: {
					"icon-image": "pr-" + prs.id,
					"icon-size": ["get", "!tns"],
					"text-field": "{" + prs.displayColumn + "}",
					"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
					"text-size": 12,
					"text-offset": [0, 0.22]
				}
			});
		},

		removePrsLayer(prs){
			this.map.removeLayer("prs-" + prs.id);
		},

		centerGeoLoc(){
			if("geolocation" in navigator){
				navigator.geolocation.getCurrentPosition(pos => {
					this.map.jumpTo({
						center: [pos.coords.longitude, pos.coords.latitude]
					});
				});
			} 
			else {
				alert("La géolocalisation n'est pas disponible sur cet appareil ou sur ce navigateur");
			}
		}, 
		changeMapStyle(){
			if(this.isMapInReloading) return;
			this.$emit("beforeStyleChange");
			this.map.getCanvas().style.cursor = "wait";
			let mapStyle = store.state.campaign.current.mapStyle;
			if(mapStyle != "satellite"){
				this.$store.commit("campaign/set_current", {mapStyle: "satellite"});
				this.map.setStyle("mapbox://styles/mapbox/satellite-v9");
			}
			else {
				this.$store.commit("campaign/set_current", {mapStyle: "standard"});
				this.map.setStyle("mapbox://styles/nextroad-rd/cl0mbzpvc001814qn1leqo1bj");
			}
			this.$emit("styleChanged");
		},

		setStyle(){
			this.$refs.prsBtnA.isActive = false;
			this.$refs.prsBtnN.isActive = false;
			this.$refs.prsBtnD.isActive = false;

			if(this.$refs.measureBtnMap.isActive === true){
				this.$refs.measureBtnMap.isActive = false;
				this.MeasuringOff();
			}

			if(this.$parent.style === "mapbox://styles/nextroad-rd/cl0mbzpvc001814qn1leqo1bj"){
				this.$parent.style = "mapbox://styles/mapbox/satellite-v9";
				this.getPrsLayer();
				this.$refs.styleBtnMap.isActive = true;
			}
			else {
				this.$parent.style = "mapbox://styles/nextroad-rd/cl0mbzpvc001814qn1leqo1bj";
				this.getPrsLayer();
				this.$refs.styleBtnMap.isActive = false;
			}
		},
		centerMap(){
			if(this.campaign === false){
				let bounds = store.state.campaign.current.bounds;
				this.map.fitBounds(bounds, {duration: 0, padding: 50});
			}
			else {
				this.$parent.fitBounds();
			}
		},

		MeasuringOn(){
			this.geojson = {
				"type": "FeatureCollection",
				"features": []
			};

			this.linestring = {
				"type": "Feature",
				"geometry": {
					"type": "LineString",
					"coordinates": []
				}
			};

			this.map.addSource("Measuring", {
				"type": "geojson",
				"data": this.geojson
			});

			this.map.addLayer({
				id: "measure-points",
				type: "circle",
				source: "Measuring",
				paint: {
					"circle-radius": 10,
					"circle-color": "#000"
				},
				filter: [
					"in", "$type", "Point"
				]
			});

			this.map.addLayer({
				id: "measure-lines",
				type: "line",
				source: "Measuring",
				layout: {
					"line-cap": "round",
					"line-join": "round"
				},
				paint: {
					"line-color": "#000",
					"line-width": 5
				},
				filter: [
					"in", "$type", "LineString"
				]
			});

			this.map.on("click", this.MeasuringEventClick);
			this.map.on("mousemove", this.MeasuringEventMousemove);
		},

		MeasuringOff(){
			this.map.getCanvas().style.cursor = "";
			this.map.off("click", this.MeasuringEventClick);
			this.map.off("mousemove", this.MeasuringEventMousemove);
			this.measureValue = false;
			this.map.removeLayer("measure-points");
			this.map.removeLayer("measure-lines");
			this.map.removeSource("Measuring");
			delete this.linestring;
			delete this.geojson;
		},

		MeasuringEventClick(e){
			const features = this.map.queryRenderedFeatures(e.point, {
				layers: ["measure-points"]
			});
            
			if(this.geojson.features.length > 1) this.geojson.features.pop();

			if(features.length){
				const id = features[0].properties.id;
				this.geojson.features = this.geojson.features.filter((point) => point.properties.id !== id);
			}
			else {
				const point = {
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": [e.lngLat.lng, e.lngLat.lat]
					},
					"properties": {
						"id": String(new Date().getTime())
					}
				};

				this.geojson.features.push(point);
			}

			if(this.geojson.features.length > 1){
				this.linestring .geometry.coordinates = this.geojson.features.map((point) => point.geometry.coordinates);
				this.geojson.features.push(this.linestring);
				this.measureValue = turf.length(this.linestring).toLocaleString();
			}
                
			this.map.getSource("Measuring").setData(this.geojson);
		},

		MeasuringEventMousemove(e){
			const features = this.map.queryRenderedFeatures(e.point, {
				layers: ["measure-points"]
			});

			this.map.getCanvas().style.cursor = features.length ? "pointer" : "crosshair";
		},

		enablePolygonDraw(){
			this.draw.changeMode("draw_polygon");
		},

	},
	mounted(){
		
	},
	created(){
		this.getPrsLayer();
	},
	destroyed(){
        
	}
};
</script>

<style lang="scss">
nav[map-nav-button]{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;

    > div{
        display: flex;
        gap: 5px;
    }

    > .measure {
        user-select: none;
        position: absolute;
        right: 0;
        bottom: 100px;
        background: #f5f5f5;
        font-size: 20px;
        padding: 5px 5px;
        margin-bottom: 5px;
    }
}
</style>
