<template>
	<div
	class="tw-h-full tw-w-[20%] tw-right-[20%] tw-bg-[#f1f1f1] tw-flex tw-items-center tw-z-20 tw-overflow-y-auto"
	ref="formMap"
	>
		<div class="tw-flex tw-h-full tw-w-full tw-justify-center tw-mt-[24px] tw-items-start ">
			<div class="tw-flex tw-flex-col tw-justify-center tw-gap-[15px] tw-w-[80%] ">
				<h2 class="tw-self-center">
					Modifier les données
				</h2>
				<div
				v-for="(column, name) in loadedDataFiltered"
				class="tw-flex tw-gap-[10px] tw-flex-col"
				:key="name + 'a'"
				>
					<v-combobox
					v-if="loadedDataFiltered[name].displayType === 'edit'"
					type="text"
					:items="dataSelect[column['dbname']]"
					:label="column['name']"
					v-model="loadedDataFiltered[name]['val']"
					outlined
					hide-details
					dense
					:id="name+'newVal'"
					/>

					<v-select
					v-if="loadedDataFiltered[name].displayType === 'select'"
					type="text"
					:items="dataSelect[column['dbname']]"
					:label="column['name']"
					v-model="loadedDataFiltered[name]['val']"
					outlined
					hide-details
					dense
					:id="name+'newVal'"
					/>

					<quill-editor
					v-if="loadedDataFiltered[name].displayType === 'text'"
					v-model="loadedDataFiltered[name]['val']"
					:options="editorOptions"
					class="quill-editor"
					/>
				</div>

				<ButtonSlot
				@click="validate()"
				>
					Valider les modifications
				</ButtonSlot>
			</div>
		</div>
	</div>
</template>

<script>
import {quillEditor} from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
	name: "AuscultationFromMap",
	components: {
		quillEditor
	},
	props: {
		loadedData: {
			type: Object,
			required: false
		},
		mapLayerId: {
			type: Number,
			required: false
		}
	},

	data(){
		return {
			editTable: [],
			gid: false,
			dataSelect: {},
			loadedDataFiltered: [],
			editorOptions: {
				theme: "snow", // or 'bubble'
				modules: {
					toolbar: [
						[
							{
								"header": [
									1, 2, false
								]
							}
						],
						[
							"bold", "italic", "underline"
						],
						[{"list": "ordered"}, {"list": "bullet"}],
						["link"],
						[{"align": []}],
						[{"color": []}, {"background": []}],
						["clean"] // remove formatting button
					]
				}
			}
		};
	},

	computed: {
		
	},
	watch: {
		loadedData(){
			if(this.loadedData !== null){
				this.setupDataSelect();
			}
		}
	},
	methods: {
		setupDataSelect(){
			let filter = [];
			Object.keys(this.loadedData).forEach(async key => {
				if(this.loadedData[key].dbname.endsWith("_edit") || this.loadedData[key].dbname.endsWith("_select") || this.loadedData[key].dbname.endsWith("_text")){
					let finishingInformation = this.loadedData[key].dbname.split("_").pop();
					filter.push({
						name: this.loadedData[key].lab, dbname: this.loadedData[key].dbname, val: this.loadedData[key].val, type: this.loadedData[key].type, displayType: finishingInformation
					});
					let responseData = await this.$api.mapLayers.getDataFromColumnName(this.mapLayerId, this.loadedData[key].dbname);
					this.dataSelect[this.loadedData[key].dbname] = [...responseData.map(item => item[this.loadedData[key].dbname])];
				}
			});

			this.loadedDataFiltered = filter;
			this.gid = this.loadedData.gid.val;
		},
		validate(){
			const keyValueArray = Object.entries(this.loadedDataFiltered).reduce((acc, [key, value]) => {
				acc[value.dbname] = value.val;
				return acc;
			}, {});

			this.$api.mapLayers.updateSchemaValues(this.mapLayerId, this.gid, keyValueArray).then(() => {
				this.$emit("layerContentChanged", this.mapLayerId);
			});
		},
		cancelValue(id){
			let index = this.editTable.findIndex(e => e === id);
			this.editTable.splice(index, 1);
		},
	},
	mounted(){
	},
};
</script>

<style lang="scss">
@import "../../../assets/scss/_variables.scss";
.form_map {
  background-color: #f1f1f1;
  height: 100%;
  display: flex;
  align-items: center;
}

.quill-editor .ql-container {
  max-height: 300px; /* Définissez ici la hauteur maximale souhaitée */
  overflow-y: auto;
}
</style>
