<template>
	<v-dialog
	v-model="dialog"
	persistent
	width="auto"
	>
		<v-card class="tw-flex tw-flex-col tw-justify-center tw-items-center">
			<v-card-title class="text-h5">
				Cette campagne est désactivée
			</v-card-title>

			<v-card-text>
				Le site de cette campagne a été désactivé. Cependant vous pouvez toujours accéder aux livrables.<br/>
				Pour réactiver l'accès, merci de contacter un administrateur
			</v-card-text>

			<v-card-actions>
				<v-spacer/>

				<MediumButtonSlot
				color="green-darken-1"
				variant="text"
				@click="destroy()"
				>
					Retour
				</MediumButtonSlot>

				<HighButtonSlot
				color="green-darken-1"
				variant="text"
				:_to="{ name: 'campaign-deliverables', params: { id: $route.params.id } }"
				>
					Accéder aux livrables
				</HighButtonSlot>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import HighButtonSlot from "../slots/buttons/High.button.slot.vue";
import MediumButtonSlot from "../slots/buttons/Medium.button.slot.vue";
export default {
	name: "SiteDeactivated",
	components: {HighButtonSlot, MediumButtonSlot},
	data(){
		return {
			dialog: true
		};
	},
	methods: {
		destroy(){
			this.dialog = false;
			this.$router.back();
			this.$destroy();
		}
	},
	mounted(){
	}
};
</script>

<style scoped>
.v-overlay__scrim {
  opacity: 0.90 !important;
  background-color: rgb(4 4 4) !important;
  border-color: rgb(0 0 0) !important;
}
</style>
