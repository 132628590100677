<template>
	<section class="FilePreviewParams">
		<v-card
		flat
		outlined
		class="FilePreviewParams__card"
		id="modale"
		>
			<v-card-text class="card_text">
				<div class="tw-w-full tw-justify-between tw-flex tw-flex-row">
					<div
					v-if="selectedFile && getExtension(selectedFile).toLowerCase() == 'pdf'"
					class="tw-w-full tw-h-full"
					>
						<pdf
						v-for="page in selectedFile.pages"
						:key="page"
						class="tw-mb-[10px] tw-w-full"
						:src="selectedFile.path"
						:page="page"
						/>
					</div>
					<template v-else-if="selectedFile && (wordExtensions.includes(getExtension(selectedFile).toLowerCase()) || excelExtensions.includes(getExtension(selectedFile).toLowerCase()))">
						<div
						class="tw-w-full tw-h-full"
						v-html="selectedFile.htmlContent"
						/>
					</template>
				</div>
			</v-card-text>
			<v-card-actions class="tw-flex tw-flex-row tw-gap-[10px] tw-justify-between">
				<ButtonSlot
				_theme="light-gray"
				@click="closePopin()"
				>
					Fermer
				</ButtonSlot>
			</v-card-actions>
		</v-card>
	</section>
</template>

<script>
import pdf from "vue-pdf";
import mammoth from "mammoth";
import {read, utils} from "xlsx";

export default {
	name: "FilePreviewModal",
	components: {
		pdf
	},
	props: {
		file: {
			type: Object
		}
	},
	data(){
		return {
			wordExtensions: [
				"doc",
				"docx"
			],
			excelExtensions: [
				"xls",
				"xlsx"
			],
			selectedFile: this.file
		};
	},
	methods: {
		closePopin(){
			this.$emit("closeCurrent", false);
		},
		getExtension(file){
			const segments = file.realPath.split(".");
  			return segments[segments.length - 1];
		},
		async getDocContent(file){
			try {
				const response = await fetch(file.path);
				const arrayBuffer = await response.arrayBuffer();
				const result = await mammoth.convertToHtml({arrayBuffer: arrayBuffer});
				return result.value;
			}
			catch (error){
				console.error("Error converting file to HTML:", error);
			}
		},
		async getExcelContent(file){
			try {
				const response = await fetch(file.path);
				const arrayBuffer = await response.arrayBuffer();
				const workbook = read(arrayBuffer, {type: "array"});
				const firstSheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[firstSheetName];
				const html = utils.sheet_to_html(worksheet);
				return html;
			}
			catch (error){
				console.error("Error converting Excel file to HTML:", error);
				return "";
			}
		},
	},
	async mounted(){
		this.selectedFile = this.file;
		if(this.wordExtensions.includes(this.getExtension(this.selectedFile))){
			this.selectedFile.htmlContent = await this.getDocContent(this.file);
		}
		else if(this.excelExtensions.includes(this.getExtension(this.selectedFile))){
			this.selectedFile.htmlContent = await this.getExcelContent(this.file);
		}
	}
};
</script>

<style lang="scss">
@import "@/assets/scss/_extends.scss";

.FilePreviewParams {
  @extend %popinView;

  .v-card {
    width: 80%;
    padding: 20px 20px;
    max-height: 80%;
    overflow: auto;
  }

  p {
	text-align: inherit !important;
  }

  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000; /* High value to ensure it is on top */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: to darken the background */
}
</style>
