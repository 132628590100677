<template>
	<v-tooltip left>
		<template v-slot:activator="{ on, attrs }">
			<ButtonSlot
			v-if="reclickEvent"
			:disabled="disabledStat"
			_icon-size="50px"
			_theme="light-gray"
			:_icon="icon"
			@click="clickEvent"
			@reclickEvent="reclickEvent"
			style="width: 40px !important"
			/>

			<ButtonSlot
			v-else
			small
			:disabled="disabledStat"
			_icon-size="25px"
			_theme="light-gray"
			:_icon="icon"
			@click="clickEvent"
			style="width: 40px !important"
			/>
		</template>
		<span>{{ tooltip }}</span>
	</v-tooltip>
</template>

<script>

export default {
	name: "MapBtn",
	props: [
		"clickEvent", "icon", "tooltip", "disabledStat", "color", "reclickEvent"
	]
};
</script>

<style lang="scss" scoped>
button {
  width: 40px !important;
  min-width: 40px !important;
  aspect-ratio: 1/1 !important;
}
</style>
